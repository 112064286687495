import React, { useState } from "react";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import config from "../config";

const ExportGameHistory = () => {
  const [gameType, setGameType] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const handleExport = async () => {
    if (!gameType || !username || !startDate || !endDate) {
      setStatus("Please fill in all fields");
      return;
    }

    const requestBody = {
      title: "CS_File",
      username: username,
      gameType: gameType,
      startedAt: startDate,
      endedAt: endDate,
    };

    setStatus("Exporting...");

    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }

      const response = await fetch(config.REST_ENDPOINT + "/api/tasks/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.fileUrl) {
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = data.fileUrl;
          a.download = `gameHistory_${gameType}.csv`;

          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);

          setStatus("Export successful, CSV file downloaded.");
        } else {
          setStatus("Export successful, but no file URL provided.");
        }
      } else if (response.status === 401) {
        setStatus("Authentication failed. Please check your credentials.");
      } else {
        const errorData = await response.json().catch(() => ({}));
        setStatus(
          `Export failed: ${response.status} ${response.statusText}. ${
            errorData.message || ""
          }`
        );
      }
    } catch (error) {
      console.error("Error: ", error);
      setStatus(
        `Error during export: ${
          error instanceof Error ? error.message : "Unknown error occurred"
        }`
      );
    }
  };

  return (
    <div
      style={{
        width: "400px",
        border: "1px solid #ccc",
        padding: "20px",
        borderRadius: "8px",
      }}
    >
      <h2 style={{ marginTop: 0 }}>Export Game History</h2>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <select
          value={gameType}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setGameType(e.target.value)
          }
          className="p-2 border rounded"
          required
        >
          <option value="">Select Game Type</option>
          <option value="ludo">ludo</option>
          <option value="rummy">rummy</option>
          <option value="callbreak">callbreak</option>
          <option value="snakesandladders">snake and ladder</option>
          <option value="skillpatti">skillpatti</option>
        </select>

        <input
          type="text"
          placeholder="Enter Username"
          value={username}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setUsername(e.target.value)
          }
          className="p-2 border rounded"
          required
        />

        <input
          type="date"
          placeholder="Select Start Date"
          value={startDate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setStartDate(e.target.value)
          }
          className="p-2 border rounded"
          required
        />

        <input
          type="date"
          placeholder="Select End Date"
          value={endDate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEndDate(e.target.value)
          }
          className="p-2 border rounded"
          required
        />

        <button
          onClick={handleExport}
          style={{
            padding: "10px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
        >
          Export History
        </button>

        {status && (
          <p
            style={{
              marginTop: "10px",
              color: status.includes("successful") ? "green" : "red",
            }}
          >
            {status}
          </p>
        )}
      </div>
    </div>
  );
};

export default ExportGameHistory;
